import { useState,useRef ,useLayoutEffect,useCallback} from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// REACT ICONS

// REDUX
import { PostContact } from '../../slices/contactReducer'; 
import  store  from '../../store'

import { ReactComponent as YourSvg } from '../../images/Component 1.svg';
import './Contact.css'

gsap.registerPlugin(ScrollTrigger);
interface contactinit {"information": {[key:string]:string}}
const Contact = ({information}:contactinit) => {
    type AppDispatch = typeof store.dispatch
    const useAppDispatch = () => useDispatch<AppDispatch>()
    const dispatch = useAppDispatch()  

    const contactRef = useRef(null)
    const input_1:any = useRef(null)
    const input_2:any = useRef(null)
    const focus_ref:any = useRef(null)
  
    

    interface formdatainit {
        Name: string
        Email: string
        Message: string
    }
    //* Variables   
    const formdatainitialvalue = {
        Name: '',
        Email: '',
        Message: ''
    }
    const [formData, setFormData] = useState<formdatainit>(formdatainitialvalue)
    const [page, setPage] = useState<number>(0)
    const [message, setMessage] = useState<string>("")
    const [formMessage, setFormMessage] = useState<string>("")
    const [errorBtn, setErrorBtn] = useState<boolean>(false)
    const [loadingState, setLoadingState] = useState<string>("none")
    const [autoFocus, setAutoFocus] = useState(false)

   
    
    //* Funtions
    const CheckForm = () => {
        let error = false
        switch (page) {
            case 0:
                if(formData?.Name === "" ){
                    setFormMessage(information?.Mail_Error_Form)  
                    error = true
                }
                break;
            case 1:
                if(formData?.Message === "" ){
                    setFormMessage(information?.Mail_Error_Form)  
                    error = true
                }
                break;
            case 2:
                if(formData?.Email === "" || formData?.Email?.indexOf("@") === -1) {
                    setFormMessage(information?.Mail_Error_Email)
                    error = true
                }
                break;
        
            default:
                if(formData?.Email === "" || formData?.Email?.indexOf("@") === -1) {
                    setFormMessage(information?.Mail_Error_Email)
                    error = true
                }else if(formData?.Name === "" || formData?.Message === "" ){
                    setFormMessage(information?.Mail_Error_Form)
                    error = true
                }
                break;
        }

        if(!error) setFormMessage("")
        
        return error
        
    }
    const HandleChange = (e:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> ) => {        
        setFormData({
          ...formData,
            [e.target.name]: e.target.value
        })
    }
    const HandleKeyDown = (event:React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement> ) => {
  
        if (event.key === 'Enter') {
            event.preventDefault()
            if(CheckForm()) return
            let newPage = page + 1        
            setPage(newPage)    
            ChangePageAnimations(newPage)
        }
    }
    const HandleNext = (event:React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault()
        if(CheckForm()) return
        let newPage = page + 1        
        setPage(newPage)    
        ChangePageAnimations(newPage)                  
    }
    
    const ChangePageAnimations = (page: number) => {
        const ctx = gsap.context(()=>{
            gsap.to(".contact-page",{
                pointerEvents: "none",
                opacity:0,
                duration:0.5
            })
            focus_ref.current.blur()        
            switch (page) {               
                case 0:
                    gsap.to('#contact-page-0',{
                        pointerEvents: "all",                        
                        opacity:1,
                        delay:0.5
                    })
                    focus_ref.current.focus()        
                    break;
                case 1:
                    gsap.to('#contact-page-1',{
                        pointerEvents: "all",                        
                        opacity:1,
                        delay:0.5
                    })
                    input_1.current.focus()        
                    break;
                case 2:
                    gsap.to('#contact-page-2',{
                        pointerEvents: "all",                        
                        opacity:1,
                        delay:0.5
                    })
                    input_2.current.focus()        
                    break;   
                case 3:                                        
                default:
                   
                    gsap.to('#contact-page-3',{
                        pointerEvents: "all",                        
                        opacity:1,
                        delay:0.5
                    })
                    //input_2.current.focus()        
                    break;
            }                                                                      
        },contactRef)
        
        return () => {
           
            ctx.revert()
        };
    }
    const HandlePrev = (event:React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault()
        let newPage = page - 1  
        setPage(newPage)
        ChangePageAnimations(newPage)
    }
    const HandleReset = ()=>{
        setErrorBtn(false);
        setFormData(formdatainitialvalue)
        setPage(0)
        const ctx = gsap.context(()=>{

            gsap.to(".contact-page",{
                pointerEvents: "none",
                opacity:0,
            })
            gsap.to('#contact-page-0',{
                pointerEvents: "all",                        
                opacity:1
            })
            focus_ref.current.focus()  
                    
            
        },contactRef)
      
        return () => {
           
            ctx.revert()
        };
    }
    const Submit = async(token:string) => {  
        if(CheckForm()) return        
        setLoadingState("flex")      
         
       await dispatch(PostContact({Email: formData?.Email, Name: formData?.Name, Message: formData?.Message, recaptchaToken: token}))            
        .then(unwrapResult)
        .then((res:any) => {
           //const res = {statusCode:500, body:{message:"message"}}
            if(res?.statusCode === 200){
                setMessage("Tack för ditt mail, jag återkommer med svar strax!")
                setFormData(formdatainitialvalue)

            }else if(res?.body?.message){
             
                setMessage(res?.body?.message)
                setErrorBtn(true)
            }else{
                setMessage("Ett oväntat fel uppstod, ladda om sidan och försök igen")
                setErrorBtn(true)
            }   
          
            setLoadingState("none")           
            ChangePageAnimations(3)   
        }) 
    }




    //*ReCaptcha
    const { REACT_APP_SITE_KEY }:any = process.env; 
    const HandleReCaptchaVerify = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();
      
        // Create an event handler so you can call the verification on button click event or form submit
        const handleReCaptchaVerify = useCallback(async (e:any) => {
          e.preventDefault()
          if (!executeRecaptcha) {
           
            return;
          }
      
          const token = await executeRecaptcha('contact_post');
          /* console.log("TOKEN: ",token) */
          //setTokenState(token)
          Submit(token)
          // Do whatever you want with the token
        }, [executeRecaptcha]);
      
        // You can use useEffect to trigger the verification as soon as the component being loaded
        /* useEffect(() => {
          handleReCaptchaVerify();
        }, [handleReCaptchaVerify]); */
      
        return(
           
            <button onClick={handleReCaptchaVerify} className='submit-btn'>{information?.Submit}</button>
      
        
         )
    };
    
 

    //* GSAP */

    /* Auto focus  */
    useLayoutEffect(() => {
    
        const ctx = gsap.context(()=>{
            ScrollTrigger.create({										        
				trigger:contactRef.current,      
				start: () => "top " + 1 + "px",			
				//markers: true,
				onEnter: ()=> { focus_ref.current.focus(); setAutoFocus(true)},
                onEnterBack: ()=> { focus_ref.current.focus(); },
				toggleActions: "play none none reverse",
			})
         
        },contactRef)
    
        return () => {
            ctx.revert()
        };
    }, [])



    //* Components
    const LoadingComponent = () => {
        return (
            <div className='loadingscreen flex__center-c' style={{display:loadingState}}>    
                <div className='loadingscreen__container'>
                    <div className='loadingscreen__orbit'>
                        <div className='loadingscreen__circle'></div>
                    </div>
                    <div className='main-icon'>
                        <YourSvg id='icon'/>
                    </div>                      
                </div>            
            </div>
        )
    }
    const NextButton = () => (
        <button type='submit' style={{display: "inline-flex",alignItems: "center"}} /* onClick={HandleNext} */>
            <p style={{fontSize:"2vmin",margin:0}}>{information?.Button}</p>            
        </button>
    )
    const PreButton = () => (
        <button className='contact__backbtn' onClick={HandlePrev}>         
             <p style={{fontSize:"1.7vmin",margin:0}}>{information?.Back}</p>
        </button>
    )

  
    return (
        <div className='container__contact flex__center-r' ref={contactRef} id='contact'>
            <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_SITE_KEY}>
            <div className='contact flex__center-r'>

                <LoadingComponent />

                
            
            <form className='flex__center-c  contact-page' id='contact-page-0' onSubmit={HandleNext} style={{pointerEvents:"all",opacity:1}}>      
                <h1 className='contact__title h1__font-medium'>{information?.Title_1}</h1>          
                <span className='contact__backbtn'></span>
                <h2 className='h2__font-medium'>{information?.Name}</h2>

                

                <input 
                    className='input__name'
                    name='Name'
                    type='text'
                    required
                    id='contact-page-0-input' 
                    onChange={HandleChange}
                    onKeyDown={HandleKeyDown}
                    autoFocus={autoFocus}
                    ref={focus_ref}
                    value={formData?.Name}
                    
                />



                <NextButton /> 

                {formMessage && <p className='p__font-medium form-error-message'>{formMessage}</p>}
            </form>  
            

           
            <form className='flex__center-c  contact-page ' id='contact-page-1' onSubmit={HandleNext} style={{pointerEvents:"none",opacity:0}}>
                <PreButton />
                <h2 className='h2__font-medium'>{information?.Message}</h2>
                <textarea      
                    name='Message'         
                    ref={input_1}    
                    onChange={HandleChange}
                    onKeyDown={HandleKeyDown}
                    value={formData?.Message}
                    required
                    
                    
                />
                 <NextButton /> 
                {formMessage && <p className='p__font-medium form-error-message'>{formMessage}</p>}
            </form>  
            


           
            <div className='flex__center-c  contact-page ' id='contact-page-2' style={{pointerEvents:"none",opacity:0}}>
                <PreButton />
                <h2 className='h2__font-medium'>{information?.Email}</h2>
                <input 
                    type='text'
                    name='Email'
                    ref={input_2}    
                    onChange={HandleChange}                    
                    value={formData?.Email}
                    required
                    
                />

                {/* Submit Button */}
                <div className='contact__form-submit flex__center-c '>             
                    <HandleReCaptchaVerify />        
                </div>
               
                {formMessage && <p className='p__font-medium form-error-message'>{formMessage}</p>}
            </div>  



            <div className='flex__center-c  contact-page ' id='contact-page-3' style={{pointerEvents:"none",opacity:0}}>
                
                {message && <p className='p__font-medium'>{message}</p> }
                {errorBtn &&
                    <button className='contact__backbtn' onClick={HandleReset}> {information?.Back}</button>
                }
                
            </div>  
            
            </div>
            </GoogleReCaptchaProvider>
        </div>
    )
}

export default Contact