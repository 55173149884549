import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';


// SELF IMPORTS
import { Iphonex } from '../../components';
import './MobileScroll.css'

interface infoinit {information:{[key:string]:string;}}
gsap.registerPlugin(ScrollTrigger);
const MobileScroll = ({information}:infoinit) => {

  const mobilescroll:any = useRef()
  const mobilescroll_frame:any = useRef()

  const mobilescroll_info:any = useRef()
  



  //* GSAP */

  /* Pin background img  */
  useLayoutEffect(() => {
    const ctx = gsap.context(()=>{

      



      gsap.set('.left-col',{              
        autoAlpha:0.2,
      })
  /*     gsap.set('.right-col',{              
        autoAlpha:0.2,                
      }) */

 
    
      gsap.to('.left-col',{      
        autoAlpha:1,                    
        scrollTrigger: {         
          trigger: mobilescroll.current,       
          start: "top top",
          /* markers: true, */
          pin:".left-col",
          toggleActions: "play none none reverse",          
        }
      })
    
    
 /*      gsap.to('.right-col',{                                 
        autoAlpha:1,
        scrollTrigger: {         
          trigger: mobilescroll.current,       
          start: "top top",
       
          pin:".right-col",
          toggleActions: "play none none reverse",          
        }
      }) */


      gsap.to('.left-col-mobile',{                          
        scrollTrigger: {         
          trigger: mobilescroll.current,       
          start: "top top",
          /* markers: true, */
          pin:".left-col-mobile",
          toggleActions: "play none none reverse",          
        }
      })
    
   /*  
      gsap.to('.right-col-mobile',{                                 
		ease: "ease",
        scrollTrigger: {         
            trigger: mobilescroll.current,       
            start: "top top",		  
        
            pin:".right-col-mobile",
            toggleActions: "play none none reverse",          
        }
      }) */



 
      gsap.to('.mobilescroll__bgimg-img',{
        yPercent: -300 , //* Om fler bilder skall läggas till så kan du ändra här -400 för 5 bilder, -300 för 4 bilder osv
        ease: 'linear',
        scrollTrigger: {         
          trigger: mobilescroll.current,       
          start: "top top",
          end: `bottom bottom `,
          scrub: true,
          /* markers:true, */
  
        }
      })
      





      gsap.to('.textcoloumn__fixed',{                                 
		ease: "linear",
        scrollTrigger: {         
          trigger: mobilescroll.current,       
          start: "top top",		  
          /* markers: true, */
          pin:".textcoloumn__fixed",
          toggleActions: "play none none reverse",          
        }
      })

     


        //* makes the text move in the right order */
        gsap.fromTo('.h1_1',{
            bottom: '-50%',            
        },{
            bottom: '110%',            
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.out',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "top top",
                end: `25% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_2',{
            bottom: '-50%',            
        },{
            bottom: '110%',            
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "20% top",
                end: `50% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_3',{
            bottom: '-50%',            
        },{
            bottom: '110%',            
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "45% top",
                end: `75% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_4',{
            bottom: '-40%',            
        },{
            bottom: '60%',            
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "60% top",
                end: `bottom middle `,
                scrub: true,
                //markers: true,        
            }
        })
      
    
        //* Controlls the Opacity in order to the text */
        gsap.fromTo('.h1_1',{            
            opacity: 0
        },{            
            opacity: 1,
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.out',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "top top",
                end: `25% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_2',{            
            opacity: 0,
        },{            
            opacity: 1,
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "15% top",
                end: `50% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_3',{            
            opacity: 0,
        },{            
            opacity: 1,
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "40% top",
                end: `75% middle `,
                scrub: true,
                //markers: true,        
            }
        })
        gsap.fromTo('.h1_4',{            
            opacity: 0,
        },{            
            opacity: 1,
            //ease: "slow(0.7,0.7, false)",
            ease:'ease.InOut',
            scrollTrigger: {         
                trigger: mobilescroll.current,       
                start: "60% top",
                end: `bottom middle `,
                scrub: true,
                //markers: true,        
            }
        })

    

      


  
    },mobilescroll)
  
    return () => {
      ctx.revert()
    };
  }, [])



    
  return (    
    <div className='mobilescroll flex 'ref={mobilescroll} id='mobilescroll'>
      
      <div className='left-col flex-c' >        

         <div className='mobilescroll__phone-frame' ref={mobilescroll_frame} >     

          {/* <div className='mobilescroll__bgimg flex-c' ref={mobilescroll_all_images}>         */}
            <Iphonex />      
          {/* </div> */}
          
         </div> 
        
      </div>

      
      <div className='textcoloumn flex-c' >    
        <div className='textcoloumn__fixed'>            
            <h3 className='h2__font-large h1_1'>{information?.First_1}<span style={{color:information?.First_Color}}>{information?.First_2}</span></h3>       
            <h3 className='h2__font-large h1_2'>{information?.Second_1}<span style={{color:information?.Second_Color}}>{information?.Second_2}</span></h3>       
            <h3 className='h2__font-large h1_3'>{information?.Third_1}<span style={{color:information?.Third_Color}}>{information?.Third_2}</span></h3>       
            <h3 className='h2__font-large h1_4'>{information?.Fourth_1}<span style={{color:information?.Fourth_Color}}>{information?.Fourth_2}</span></h3>                   
        </div>
      </div>




      <div className='left-col-mobile flex-c' >        

         <div className='mobilescroll__phone-frame' ref={mobilescroll_frame} >     

          {/* <div className='mobilescroll__bgimg flex-c' ref={mobilescroll_all_images}>         */}
            <Iphonex />      
          {/* </div> */}
          
         </div> 
        
      </div>

      

     

    </div>
  )
}

export default MobileScroll