import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
interface Iprops {
    information: {[key:string]: string}
}
const Footer = (props:Iprops) => {
    const { information } = props
    return (
        <Box  id='contact' className="flex__center-c" component="footer" bgcolor="#292929" sx={{p:"1rem 0 ",height:"100vh",justifyContent:"space-evenly",position:"relative"}} color="primary.main">
            <Typography variant='h1'>                
                {information?.Title_1} 
            </Typography>
            

            <Box className="flex__center-r">
                <MailOutlineIcon sx={{mr:"1rem"}}/>
            
                <Typography variant='h3' color="#ffffff">
                    {information?.email} 
                </Typography>
            </Box>
           
        </Box>
    )
}

export default Footer