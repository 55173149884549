import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import { Link } from 'react-scroll'
import { useNavigate } from 'react-router-dom';

import "/node_modules/flag-icons/css/flag-icons.min.css";


// React icons
import { MdWeb, MdOutlineWebStories } from 'react-icons/md'
import { BsCardText,BsFillPeopleFill } from 'react-icons/bs'
import { FiFigma } from 'react-icons/fi'
import { SiTypescript } from 'react-icons/si'
import { FaAws } from 'react-icons/fa'
import { CiMobile3 } from 'react-icons/ci'
import { MdLeaderboard } from 'react-icons/md'
import { HiOutlineMail } from 'react-icons/hi'
import { MdOutlineDevicesOther } from 'react-icons/md'


// Self imports
import { ReactComponent as Logo } from '../../images/Component 1.svg';
import './Navbar.css'

interface infoinit {information:{[key:string]:string;}}

const Navbar = (props:infoinit) => {
    const { information } = props
    const navigate = useNavigate()



    //* State


    const navColor = "aqua"
    const navColorOpac = "rgba(0, 255, 255, 0.248)"
    const navColorDark = "rgb(0, 169, 169)"

    // Ref
    const ref:any = useRef()
    const tl1:any = useRef()

    //UseEffect

    // Check language
    const [language, setLanguage] = useState<boolean>(false) // false == english , true === swedish
    useEffect(() => {
        const currentLang = localStorage.getItem("language");
        if(!currentLang){
            localStorage.setItem("language","en");
            setLanguage(false)
        }else if(currentLang === "en"){
            localStorage.setItem("language","en");
            setLanguage(false)
        }else{
            localStorage.setItem("language","se");
            setLanguage(true)
        }   
        return () => {      
        }
    }, []
    )
    const SetLanguage = () => {
        const currentLang = localStorage.getItem("language");
        if(currentLang === "en") {
            localStorage.setItem("language","se")           
            setLanguage(true) 
        }else{
            localStorage.setItem("language","en")    
            setLanguage(false) 
        }         
        window.location.reload()     
    }
    
    
    //* GSAP */
    const NavbarHover = (col:number) => {

        const ctx = gsap.context(()=>{
            tl1.current = gsap.timeline()		                
            switch (col) {
                case 1:
                    tl1.current.to(ref.current,{
                        left: 0,
                        borderColor: navColor,
                        backgroundColor: "rgba(41, 41, 41, 1)",
                        opacity:1,
                        duration: 0.5,
                        ease: "linear",          
                    },0)          		
                    .to('.component__navbar #navbar-logo path',{                        
                        fill: navColor,
                        duration: 0.5,
                        ease: "linear",          
                    },0)          		                
                    .to('.navbar-logo-container #navbar-logo',{
                        left: '0',       
                        duration: 0.5,
                        ease: "linear",          
                    },0)          					                                               									      
                break;                   
                default:
                    tl1.current.to(ref.current,{
                        left: "-147px",
                        borderColor: navColorOpac,
                        backgroundColor: "rgba(41, 41, 41, 0)",
                        opacity:0.25,
                        duration: 0.5,
                        ease: "linear",          
                    },0)   
                    .to('.component__navbar #navbar-logo path',{                        
                        fill: navColorDark,
                        duration: 0.5,
                        ease: "linear",          
                    },0)                            
                    .to('.navbar-logo-container #navbar-logo',{
                        left: '150px',       
                        duration: 0.25,
                        ease: "linear",          
                    },0.25)  
			 
                   
                break
            }
			

        },ref)
		
		return () => {				
            ctx.revert()
		};
		
       
    }  
   
    
    return (
        <div className='component__navbar flex-c' ref={ref} onMouseEnter={()=>NavbarHover(1)}  onMouseLeave={()=>NavbarHover(0)}>
            
            <Link className="navbar-logo-container" activeClass="active" to="header" spy={true} smooth={true} offset={50} duration={500}>
                <Logo id='navbar-logo' /> 
            </Link>
            
            <div className='navbar__section flex-c'>
                <p className='p__font-xsmall section-title-p'>{information?.Title_1}</p>
                <div className='navbar__button navbtn1'>
                    <Link activeClass="active" to="secondheader" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Intro}<BsCardText className='react-icon-navbar'/> </p>
                    </Link>
                </div>
                <div className='navbar__button navbtn2'>
                    <Link activeClass="active" to="websites" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Websites} <MdWeb className='react-icon-navbar'/> </p>
                    </Link>
                </div>
            </div>    



            <div className='navbar__section flex-c'>
                <p className='p__font-xsmall section-title-p'>{information?.Title_2}</p>
                <div className='navbar__button navbtn3'>
                    <Link activeClass="active" to="tools1" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub1}<FiFigma className='react-icon-navbar'/> </p>
                    </Link>
                </div>
                <div className='navbar__button navbtn4'>
                    <Link activeClass="active" to="tools2" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub2} <SiTypescript className='react-icon-navbar'/> </p>
                    </Link>
                </div>
                <div className='navbar__button navbtn5'>
                    <Link activeClass="active" to="tools3" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub3}<FaAws className='react-icon-navbar'/> </p>
                    </Link>
                </div>
                <div className='navbar__button navbtn6'>
                    <Link activeClass="active" to="mobilescroll" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub4}<BsFillPeopleFill className='react-icon-navbar'/> </p>
                    </Link>
                </div>
            {/*     <div className='navbar__button navbtn7'>
                    <Link activeClass="active" to="fadeheader" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>Css / Gsap <MdLeaderboard className='react-icon-navbar'/> </p>
                    </Link>
                </div> */}
                <div className='navbar__button navbtn8'>
                    <Link activeClass="active" to="contact" spy={true} smooth={true} offset={50} duration={1000}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Contact}<HiOutlineMail className='react-icon-navbar'/> </p>
                    </Link>
                </div>
            </div>   
            
                <div className='navbar__language'>
                    
                    <div className="checkbox-wrapper-59">
                    <label className="switch">
                        <input type="checkbox" value={language} onChange={SetLanguage} checked={language}/>
                        {/* <span className="slider"></span> */}
                        <span className="fi fi-se fis slider"></span> 
                        <span className="fi fi-gb fis slider"></span> 
                    </label>
                    </div>
                  
                </div>
            
            {/* <div className='navbar__right flex__center-c'>

            </div> */}
            
        </div>
    )
}

export default Navbar