import { useEffect,useState, useLayoutEffect, useRef } from 'react';


// SELF IMPORTS
import { Device } from '../../components/index';
import './SecondHeader.css'

interface infoinit {
  information:{
    [key:string]: string;    
  }
}
const SecondHeader = ({information}:infoinit) => {
	const secondheader_phone:any = useRef()


	
		
	return (    
		<div className='secondheader flex__center-c panel' id='secondheader'>

		<div className='secondheader__info'>
			<h3 className='h3__font-large'>{information?.First_Row_1}<span>{information?.First_Row_2}</span></h3>          
			<h3 className='h3__font-large'>{information?.Second_Row_1}<span>{information?.Second_Row_2}</span></h3>          
			<h3 className='h3__font-large'>{information?.Third_Row_1}<span>{information?.Third_Row_2}</span></h3>          
		</div>

		<Device DF1={information?.Device_First_1} DF2={information?.Device_First_2} DS1={information?.Device_Second_1} DS2={information?.Device_Second_2} DT1={information?.Device_Third_1} DT2={information?.Device_Third_2} />

		

		{/*   <div className='secondheader__info flex' >
			<h3 className='h3__font-large'>{information?.First_Element}<span>{information?.Second_Element}</span></h3>          
		</div>

		<div className='secondheader__phone flex__center-r' ref={secondheader_phone}>          
			<div className='secondheader__phone-phone'>            
			<img src={'/img/introtext.webp'} alt="phone" />
			<div id='phone-top-bar' />
			<div id='phone-bottom-bar' />
			</div>
		</div> */}

		</div>
	)
}

export default SecondHeader