import { configureStore } from "@reduxjs/toolkit";
import contactReducer from './slices/contactReducer'
const reducer = {   
    contact: contactReducer,    
}

const store = configureStore({
    reducer:reducer
})

export default store

