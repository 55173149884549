import { useState,useEffect, useLayoutEffect, useRef }from 'react'
import { useNavigate } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from "react-scroll";

// react icons
import { MdWeb, MdOutlineWebStories } from 'react-icons/md'
import { BsCardText,BsFillPeopleFill } from 'react-icons/bs'
import { FiFigma } from 'react-icons/fi'
import { SiTypescript } from 'react-icons/si'
import { FaAws } from 'react-icons/fa'
import { CiMobile3 } from 'react-icons/ci'
import { MdLeaderboard } from 'react-icons/md'
import { HiOutlineMail } from 'react-icons/hi'
import { MdOutlineDevicesOther } from 'react-icons/md'



// Self imports
import { ReactComponent as Logo } from '../../images/Component 1.svg';
import './MobileNavbar.css'

interface infoinit {information:{[key:string]:string;}}
const MobileNavbar = ({information}:infoinit) => {
    const navigate = useNavigate()

    //* State

    const [toggleNavbarMenu, setToggleNavbarMenu] = useState<boolean>(false)
    const [mobileNavbarStyle, setMobileNavbarStyle] = useState<any>({pointerEvents:"none",opacity:0,clipPath:"circle(1% at 100% 0%)"})
    const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})


	  // Check language
	  const [language, setLanguage] = useState<boolean>(false) // false == english , true === swedish
	  useEffect(() => {
		  const currentLang = localStorage.getItem("language");
		  if(!currentLang){
			  localStorage.setItem("language","en");
			  setLanguage(false)
		  }else if(currentLang === "en"){
			  localStorage.setItem("language","en");
			  setLanguage(false)
		  }else{
			  localStorage.setItem("language","se");
			  setLanguage(true)
		  }   
		  return () => {      
		  }
	  }, []
	  )
	  const SetLanguage = () => {
		  const currentLang = localStorage.getItem("language");
		  if(currentLang === "en") {
			  localStorage.setItem("language","se")           
			  setLanguage(true) 
		  }else{
			  localStorage.setItem("language","en")    
			  setLanguage(false) 
		  }         
		  window.location.reload()     
	  }
	  
    
    const navigateClick = (location=""):any => {
        if(location){      
          navigate(location);      
        }
        setMobileNavbarStyle({pointerEvents:"none",opacity:0,clipPath:"circle(1% at 100% 0%)"})   
        setSpanStyle(prev => ({...prev,
          span1:{},
          span2:{},
          span3:{}
    
        })) 
        
        setToggleNavbarMenu(false)
	}
	const ToggleMobileMenu = (toggle:boolean) => {
	if(toggle){
		setMobileNavbarStyle({pointerEvents:"none",opacity:0,clipPath:"circle(1% at 100% 0%)"})   
		setSpanStyle(prev => ({...prev,
		span1:{},
		span2:{},
		span3:{}

		})) 
			
	}else{
		setMobileNavbarStyle({pointerEvents:"auto",opacity:1,clipPath:"circle(150% at 100% 0%)"})   
		setSpanStyle(prev => ({...prev,
		span1:{transform: "rotate(45deg) translate(-3px, -1px)"},
		span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" },
		span3:{transform: "rotate(-45deg) translate(0, -1px)" }

		})) 
	}
	setToggleNavbarMenu(!toggle)
	}


  return (
    <div className='component__mobilenavbar'>

        {/* MOBILE hamburger */}
        <div className='navbar-small__icon flex__center-c' onClick={()=>ToggleMobileMenu(toggleNavbarMenu)}>
		
            <span style={spanStyle.span1}></span>
            <span style={spanStyle.span2}></span>
            <span style={spanStyle.span3}></span>               

			
        </div>      

        {/* MOBILE menu */}
        <div className='navbar-small flex-c' style={mobileNavbarStyle}>                                
            <div className='navbar__icon' >
                <Link onClick={()=>{navigateClick("/")}} className="footer__information__icon-link" to="container__header" spy={true} smooth={true}>
                    <h1 className='h2__font-medium' onClick={()=>navigate("/")}><Logo id='react-icon-logo-mobile'/></h1>
                </Link>
            </div>
            {/* Button container */}
            <div className='navbar-small-container flex__center-c'>
                <div className='flex__center-c'>
                    <p className='p__font-small section-title-p'>{information?.Title_1}</p>
                    <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="secondheader" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)} >
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Intro}</p>
                        <BsCardText className='react-icon-navbar'/>
                    </Link>
                    <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="websites" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                        <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Websites}</p>
                        <MdOutlineDevicesOther className='react-icon-navbar'/>
                    </Link>
                </div>
                <div className='flex__center-c'>
                    <p className='p__font-small section-title-p'>{information?.Title_2}</p>
                  
                        <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="tools1" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub1}</p>
                            <FiFigma className='react-icon-navbar'/> 
                        </Link>
              
                    
                        <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="tools2" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub2}</p>
                            <SiTypescript className='react-icon-navbar'/> 
                        </Link>
                    
                    
                        <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="tools3" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub3}</p>
                            <FaAws className='react-icon-navbar'/> 
                        </Link>
                    
                    
                        <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="mobilescroll" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Sub4}</p>
                            <BsFillPeopleFill className='react-icon-navbar'/> 
                        </Link>
                    
                    
                     {/*    <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="fadeheader" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>Cloud </p>
                            <MdLeaderboard className='react-icon-navbar'/> 
                        </Link> */}
                    
                    
                        <Link className='p__font-medium no-border flex__center-r' activeClass="active" to="contact" spy={true} smooth={true} offset={50} duration={1000} onClick={()=>ToggleMobileMenu(true)}>
                            <p className='p__font-xsmall section-sub-p flex__center-r'>{information?.Contact}</p>
                            <HiOutlineMail className='react-icon-navbar'/> 
                        </Link>
                    
                </div>
            </div>
                        

            <div className='navbar__language'>
                    
                <div className="checkbox-wrapper-59">
                <label className="switch">
                    <input type="checkbox" value={language} onChange={SetLanguage}  checked={language}/>
                    {/* <span className="slider"></span> */}
                    <span className="fi fi-se fis slider"></span> 
                    <span className="fi fi-gb fis slider"></span> 
                </label>
                </div>
                  
            </div>


        </div>
    </div>
  )
}

export default MobileNavbar