import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
// SELF IMPORTS
import './FadeHeader.css'

interface infoinit {
  information:{
    First_Element: string
    Second_Element: string
    Img1: string
    Img2: string
    First_Element_Color: string
    Second_Element_Color: string

  }
}
gsap.registerPlugin(ScrollTrigger);
const FadeHeader = ({information}:infoinit) => {
 
  const fadeheader:any = useRef()
  const fadeheader_img:any = useRef()
  



  //* GSAP */

  /* Pin background img  */
  useLayoutEffect(() => {
    const ctx = gsap.context(()=>{

      gsap.set('.fadeheader__bgimg-img',{
        filter:" brightness(1)"
     
      })
      gsap.set('.fadeheader__info',{
        zIndex:"2",
        color: "white",
        
      })

          
      gsap.to('.fadeheader__bgimg',{                      
        scrollTrigger: {         
          trigger: fadeheader.current,       
          start: "top top", 
          end: "bottom bottom",
         /*  markers: true, */
          pin: fadeheader_img.current,
          toggleActions: "play none none reverse",          
        }
      })
      gsap.to('.fadeheader__bgimg-img',{                      
       
        filter: "brightness(0.2)",
        scrollTrigger: {         
          trigger: fadeheader.current,       
          start: "top top",     
          end: "bottom bottom",
        /*   markers: true, */
          onLeave: ()=> {gsap.to('.fadeheader__bgimg-img',{filter:"brightness(0.8)"})},          				  
				  onEnterBack: ()=> {gsap.to('.fadeheader__bgimg-img',{filter:"brightness(0.2)"})},          				  
				  /* onLeaveBack: ()=> {gsap.to('.navbtn8 p',{color:"#ffffff"});gsap.to('.navbtn8 .react-icon-navbar',{color:"#ffffff"})}, */
          toggleActions: "play none none reverse",          
        }
      })
      gsap.to('#fadeimg',{                      
        alpha:0,
        scrollTrigger: {         
          trigger: fadeheader.current,       
          start: "center center",         
          toggleActions: "play none none reverse",          
        }
      })


      
      




    },fadeheader)
  
    return () => {
      ctx.revert()
    };
  }, [])
 
    
  return (    
    <div className='fadeheader flex-c 'ref={fadeheader} id='fadeheader'>

      <div className='fadeheader__bgimg flex__center-c' ref={fadeheader_img}>
        <img className='fadeheader__bgimg-img' src={information?.Img1} alt={`fadeheader1`} />
        <img className='fadeheader__bgimg-img' id="fadeimg" src={information?.Img2} alt={`fadeheader2`} />
      </div>

      <div className='fadeheader__info flex-c'>
        <h3 className='h2__font-large'>{information?.First_Element}<span style={{color:information?.First_Element_Color}}>{information?.First_Element}</span></h3>       
        <h3 className='h2__font-large'>{information?.First_Element}<span style={{color:information?.Second_Element_Color}}>{information?.Second_Element}</span></h3>       
      </div>
  

    </div>
  )
}

export default FadeHeader