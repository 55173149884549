import React,{ useLayoutEffect,useRef } from 'react'

// GSAP
import { gsap } from 'gsap'

//Swiper

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade,Autoplay  } from "swiper";

import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";




import './Device.css'
interface deviceinit {[key:string]:string}
const Device = ({DF1, DF2, DS1, DS2, DT1, DT2}:deviceinit) => {
    const pcref = useRef(null)
    const pcref_Mobile = useRef(null)

    const tl:any = useRef(null)
    const tl1:any = useRef(null)
    
    const ImagesRef1:any = useRef(null)
    const ImageRef_1 = useRef(null)

    

    const ImagesRef2:any = useRef(null)
    const ImageRef_2 = useRef(null)

    const ImagesRef3:any = useRef(null)
    const ImageRef_3 = useRef(null)


    const onHover = (column:number) => {
        switch (column) {
            case 1:
                ImagesRef1.current.play()         
                ImagesRef2.current.reverse()   
                ImagesRef3.current.reverse()   
                break;
            case 2:
                ImagesRef2.current.play()
                ImagesRef1.current.reverse()   
                ImagesRef3.current.reverse()   
                break
            case 3:
                ImagesRef3.current.play()
                ImagesRef1.current.reverse()   
                ImagesRef2.current.reverse()   
                break
            default:
                break;
        }
       
    }
   
    const onLeave = (column:number) => {
        switch (column) {
            case 1:
                ImagesRef1.current.reverse()      
                break;
            case 2:
                ImagesRef2.current.reverse()
                break
            case 3:
                ImagesRef3.current.reverse()
                break
            default:
                break;
        }
       
    }
   
    const onLeaveFull = () => {     
        ImagesRef1.current.reverse()           
        ImagesRef2.current.reverse()     
        ImagesRef3.current.reverse()                    
    }






    useLayoutEffect(() => {
        const ctx = gsap.context(()=>{
            tl.current = gsap.timeline()


            ImagesRef1.current = gsap.to(ImageRef_1.current,{
                width:"50%",       
                duration: 0.5,
                ease: "linear",                
            })                      
            ImagesRef2.current = gsap.to(ImageRef_2.current,{
                width:"50%",
                duration: 0.5,
                ease: "linear",          
            })
            ImagesRef3.current = gsap.to(ImageRef_3.current,{
                width:"50%",
                duration: 0.5,
                ease: "linear",          
            })
            
        },pcref)
      
        return () => {
          ctx.revert()
        };
    }, [])

    const MobileClick = (col:number) => {
        const ctx = gsap.context(()=>{
            tl1.current = gsap.timeline()
           /*  .to('.column-subtitle-mobile',{
                height:"0",
                opacity:0,                
            },0) */
           /*  .to('.header__column-mobile',{
                height:"100%",
                duration: 0.5,
                ease: "linear",          
            },0) */
            
            switch (col) {
                case 1:
                    tl1.current.to('.column1',{
                        height:"200%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)             
                    .to('.column2',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)
                    .to('.column3',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)

                    .to('.column1 .column-subtitle-mobile',{
                        height:"100%",
                        opacity:1,
                        duration: 0.5,
                        ease: "linear",          
                    },0.5)
                    .to('.column2 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    .to('.column3 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    
                    break;
                case 2:
                    tl1.current.to('.column2',{
                        height:"200%",
                        duration: 0.5,
                        ease: "linear",          
                    })             
                    .to('.column1',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)
                    .to('.column3',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)


                    .to('.column2 .column-subtitle-mobile',{
                        height:"100%",
                        opacity:1,
                        duration: 0.5,
                        ease: "linear",          
                    },0.5)
                    .to('.column1 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    .to('.column3 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    break;
                case 3:
                    tl1.current.to('.column3',{
                        height:"200%",
                        duration: 0.5,
                        ease: "linear",          
                    })           
                    .to('.column1',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)
                    .to('.column2',{
                        height:"100%",
                        duration: 0.5,
                        ease: "linear",          
                    },0)


                    .to('.column3 .column-subtitle-mobile',{
                        height:"100%",
                        opacity:1,
                        duration: 0.5,
                        ease: "linear",          
                    },0.5)
                    .to('.column1 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    .to('.column2 .column-subtitle-mobile',{
                        height:"0",
                        opacity:0,                
                    },0)
                    break;
            
                default:
                    break;
            }


        },pcref_Mobile)
      
        return () => {
          ctx.revert()
        };
    }   



    return (
        <div className='component__device flex__center-c'>

            {/* Screen */}
            <div className='device__frame'>

                {/* REGULAR SCREEN 1000px and up */}
                <div className='device__screen flex__center-r' ref={pcref} onMouseLeave={()=>onLeaveFull()}>

                    <div className='header__column column1 flex__center-c' onMouseEnter={()=>onHover(1)} onMouseLeave={()=>onLeave(0)} ref={ImageRef_1} 
                        style={{backgroundImage:"url('/img/device1.webp')"}}>    
                        <div className='flex__center-c'>
                            <p className='p__font-small column-title'>{DF1}</p>    
                            {/* <div className='column-line'></div> */}
                            <p className='p__font-xsmall column-subtitle'>{DF2}</p>    
                        </div>                
                    </div>

                    <div className='header__column column2 flex__center-c' onMouseEnter={()=>onHover(2)} onMouseLeave={()=>onLeave(0)} ref={ImageRef_2}
                        style={{backgroundImage:"url('/img/device2.webp')"}}>                        
                        
                        <div className='flex__center-c'>
                            <p className='p__font-small column-title'>{DS1}</p>    
                            {/* <div className='column-line'></div> */}
                            <p className='p__font-xsmall column-subtitle'>{DS2}</p>    
                        </div> 
                    </div>

                    <div className='header__column column3 flex__center-c' onMouseEnter={()=>onHover(3)} onMouseLeave={()=>onLeave(0)} ref={ImageRef_3}
                        style={{backgroundImage:"url('/img/device3.webp')"}}>                        
                        
                        <div className='flex__center-c'>
                            <p className='p__font-medium column-title'>{DT1}</p>    
                          {/*   <div className='column-line'></div> */}
                            <p className='p__font-xsmall column-subtitle'>{DT2}</p>    
                        </div> 
                    </div>

            
                </div>

                {/* Mobile screen 1000px and down */}
                <div className='device__screen-mobile flex__center-c' ref={pcref_Mobile} >
                    <div className='header__column-mobile column1 flex__center-c' style={{backgroundImage:"url('/img/device1.webp')", cursor:"pointer"}} onClick={()=>MobileClick(1)}>
                        <div className='flex__center-c'>
                            <p className='h2__font-medium column-title-mobile'>{DF1}</p>    
                            <div className='column-line'></div>
                            <p className='p__font-xsmall column-subtitle-mobile'>{DF2}</p>    
                        </div>   
                    </div>
                
                    <div className='header__column-mobile column2 flex__center-c' style={{backgroundImage:"url('/img/device2.webp')", cursor:"pointer"}} onClick={()=>MobileClick(2)}>
                        <div className='flex__center-c'>
                            <p className='h2__font-medium column-title-mobile'>{DS1}</p>    
                            <div className='column-line'></div>
                            <p className='p__font-xsmall column-subtitle-mobile'>{DS2}</p>    
                        </div>   
                    </div>
                
                    <div className='header__column-mobile column3 flex__center-c' style={{backgroundImage:"url('/img/device3.webp')", cursor:"pointer"}} onClick={()=>MobileClick(3)}>
                        <div className='flex__center-c'>
                            <p className='h2__font-medium column-title-mobile'>{DT1}</p>    
                            <div className='column-line'></div>
                            <p className='p__font-xsmall column-subtitle-mobile'>{DT2}</p>    
                        </div>   
                    </div>                                      
                </div>


                <div className='device__frame_buttons'>
                    <div className='device__frame_buttons_1' />
                    <div className='device__frame_buttons_2' />
                    <div className='device__frame_buttons_3' />
                    <div className='device__frame_buttons_4' />
                </div>
                <div className='device__topcamera' />
            </div>
            <div className='device__keyboard'>
                <div className='device__keyboard-hatch'>

                </div>
            </div>



        </div>
    )
}

export default Device