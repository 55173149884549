import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import InertiaPlugin, { gsap, CSSPlugin }  from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from "gsap/Draggable";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


//mui
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// self
import { Navbar,MobileNavbar,Footer } from './components/index'
import { Header, SecondHeader, Websites,Websites2, Tools, MobileScroll,FadeHeader, Contact } from './container/index';
import './App.css';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(Draggable);

export let theme = createTheme({    
    palette: {
        primary: {
            main: '#00ffff',                       
            dark: '#005393' ,
            light: "#80d1f5",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#00ff80',
            light:"#b64600",
            dark:"#3c70d7",
            contrastText:"#000000"
            
        },
        info: {
            main: "#ffffff",
            dark:"#e0e0e0",
        },
        text: {
            primary:  "#000000",
            //secondary:"#ffffff",
            
        },
        
        
    },
    typography: {    
        h1: {            
            //color: 'text.primary',            
            textAlign: 'center',
            fontSize:"5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"5vmax",
            } 
        },
        h2: {            
            //color: 'text.primary',
            textAlign: 'center',
            fontSize:"4vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"4vmax",
            } 
        },
        h3: {            
            color: 'text.primary',
            textAlign: 'center',
            fontSize:"3vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"3vmax",
            }  
        },
        h4: {            
            color: 'text.primary',
            textAlign: 'center',
            fontSize:"2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"2vmax",
            } 
        },
        h5: {            
            color: 'text.primary',
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            } 
        },
        h6: {            
            color: 'text.primary',
            textAlign: 'center',
            fontSize:"1.2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.2vmax",
            } 
        },
        subtitle1: {
            color:"text.primary",
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            }  
        },
        subtitle2: {         
            color:"text.primary",   
            textAlign: 'center',
            fontSize:"0.8vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"0.8vmax",
            } 
        },      
        button: {            
            textAlign: 'center',
            fontSize:"1vmax",
            fontWeight:"400" 
        },
        
        },
    components: {
        MuiTypography: {
            defaultProps: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',              
                subtitle1: 'p',
                subtitle2: 'h2',
                body1: 'span',
                body2: 'span',
            },
            },
        },
        MuiButton: {
            defaultProps: {          
               // color:"secondary",   
                sx:{
                    fontSize:"1.2vmin",  
                    "@media (max-width:600px)": {
                        fontSize:"1.2vmax",
                    }, 
                    fontWeight:"400"
                }                                                             
            },
            
        },       
      /*   MuiMobileStepper:{
            defaultProps: {          
                color:"text.secondary !important",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"text.secondary !important",
                    }
                }
            }
        }, */
        MuiStepLabel:{
            defaultProps: {                                        
                sx:{                    
                    '& .MuiStepLabel-label.Mui-active':{
                        color:"secondary.dark",
                    },
                    '& .MuiStepLabel-label.Mui-completed':{
                        color:"secondary.main",
                    },
                    
                }
            }
        },
        
        MuiRadio:{
            defaultProps: {          
                color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                        // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                    
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                    
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    }, 
});

function App() {
	interface infoinit {
		[key:string]: {[key:string]:string}
	}

	const [information, setInformation] = useState<infoinit>({} as infoinit)

	


	const app:any = useRef()
	var navColor = 	"aqua"

	useEffect(() => {       
		window.scrollTo(0,0)  //? without this when page is loaded it will scroll to the bottom
		const currentLang = localStorage.getItem("language") 

		fetch(`/info/info_${currentLang}.json`)
		.then(response => {
		
		return response.json();
		}).then(data => {          
			setInformation(data);                
		}).catch((e: Error) => {         
			return
		});
	}, [])





  //* GSAP */
   /* When scrolling horizontal between 2 => 3 websites  */
  /*  useLayoutEffect(() => {
    const ctx = gsap.context(() => {           
      let mm = gsap.matchMedia()

      mm.add("(max-width: 900px)", () => {
        
        gsap.set(".container__secondheader", {
          width: "100vw",
          height: "200vh",
          display:"flex",
          flexDirection: "column",
        })
      })

      mm.add("(min-width: 900px)", () => {
      
        
      let sections = gsap.utils.toArray(".panel");
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: secondheader.current,
          pin: true,
          scrub: true,
          snap: 1 / (sections.length - 1),
          end: () => "+=" + secondheader.current.offsetWidth,
        }
      })       
        
       
    }) 
        
     
    }, app); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

 */

	/* Pin background img  */
	useLayoutEffect(() => {
		const ctx = gsap.context(()=>{

	
	
			ScrollTrigger.create({										        
				trigger:'.secondheader',       
				start: "top top",
				end: "bottom top",
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn1 p',{color:navColor}); gsap.to('.navbtn1 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn1 p',{color:"#ffffff"});gsap.to('.navbtn1 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn1 p',{color:navColor}) ; gsap.to('.navbtn1 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn1 p',{color:"#ffffff"});gsap.to('.navbtn1 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",          
			})
			
			ScrollTrigger.create({										        
				trigger:'.websites2',      
				start: "top top",
				end: "bottom top",
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn2 p',{color:navColor}); gsap.to('.navbtn2 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn2 p',{color:"#ffffff"});gsap.to('.navbtn2 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn2 p',{color:navColor}) ; gsap.to('.navbtn2 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn2 p',{color:"#ffffff"});gsap.to('.navbtn2 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})

			ScrollTrigger.create({										        
				trigger:'.tools1',      
				start: "top top",
				end: "bottom top",
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn3 p',{color:navColor}); gsap.to('.navbtn3 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn3 p',{color:"#ffffff"});gsap.to('.navbtn3 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn3 p',{color:navColor}) ; gsap.to('.navbtn3 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn3 p',{color:"#ffffff"});gsap.to('.navbtn3 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})


			ScrollTrigger.create({										        
				trigger:'.tools2',      
				start: "top top",				
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn4 p',{color:navColor}); gsap.to('.navbtn4 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn4 p',{color:"#ffffff"});gsap.to('.navbtn4 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn4 p',{color:navColor}) ; gsap.to('.navbtn4 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn4 p',{color:"#ffffff"});gsap.to('.navbtn4 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})

			ScrollTrigger.create({										        
				trigger:'.tools3',      
				start: "top top",				
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn5 p',{color:navColor}); gsap.to('.navbtn5 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn5 p',{color:"#ffffff"});gsap.to('.navbtn5 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn5 p',{color:navColor}) ; gsap.to('.navbtn5 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn5 p',{color:"#ffffff"});gsap.to('.navbtn5 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})

			ScrollTrigger.create({										        
				trigger:'.mobilescroll',      
				start: "top top",				
				/* markers: true, */
				onEnter: ()=> {gsap.to('.navbtn6 p',{color:navColor}); gsap.to('.navbtn6 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn6 p',{color:"#ffffff"});gsap.to('.navbtn6 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn6 p',{color:navColor}) ; gsap.to('.navbtn6 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn6 p',{color:"#ffffff"});gsap.to('.navbtn6 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})

	/* 		ScrollTrigger.create({										        
				trigger:'.fadeheader',      
				start: "top top",				
		
				onEnter: ()=> {gsap.to('.navbtn7 p',{color:navColor}); gsap.to('.navbtn7 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn7 p',{color:"#ffffff"});gsap.to('.navbtn7 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn7 p',{color:navColor}) ; gsap.to('.navbtn7 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn7 p',{color:"#ffffff"});gsap.to('.navbtn7 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			}) */

			ScrollTrigger.create({										        
				trigger:'#contact',      
				start: () => "top " + 1 + "px",		
			/* 	markers: true, */
				onEnter: ()=> {gsap.to('.navbtn8 p',{color:navColor}); gsap.to('.navbtn8 .react-icon-navbar',{color:navColor})},
				onLeave: ()=> {gsap.to('.navbtn8 p',{color:"#ffffff"});gsap.to('.navbtn8 .react-icon-navbar',{color:"#ffffff"})},
				onEnterBack: ()=> {gsap.to('.navbtn8 p',{color:navColor}) ; gsap.to('.navbtn8 .react-icon-navbar',{color:navColor})},
				onLeaveBack: ()=> {gsap.to('.navbtn8 p',{color:"#ffffff"});gsap.to('.navbtn8 .react-icon-navbar',{color:"#ffffff"})},
				toggleActions: "play none none reverse",
			})

		/* 	gsap.to('.navbtn2 p',{      
				color:navColor,                    
				scrollTrigger: {         
				trigger:'.mobilescroll',       
				start: "top top",
				markers: true,
		
				toggleActions: "play none none reverse",          
				}
			}) */
			
						
	
		},app)
	
		return () => {
		ctx.revert()
		};
	}, [])





	return (
		<div className="App " ref={app} id='idApp'>
		<Router>
            <ThemeProvider theme={theme}>
            {/* NAVBAR */}
                <Navbar information={information?.Navbar}/>
                <MobileNavbar information={information?.Navbar}/>
                <Routes>
                    <Route path='/' element={
                        <div>                    
                            <Header information={information?.Header}/>     
                            <SecondHeader information={information?.SecondHeader}/>          
                            {/* <Websites information={information?.Websites}/> */}
                            <Websites2 information={information?.Websites2}/>
                            <Tools  information={information?.Tools1} componentNumber={1}/>
                            <Tools  information={information?.Tools2} componentNumber={2}/> 
                            <Tools  information={information?.Tools3} componentNumber={3}/> 
                            <MobileScroll information={information?.MobileScroll} />
                            {/* <FadeHeader information={information?.FadeHeader} /> */}
                          {/*   <Contact information={information?.Contact} /> */}
                          <Footer information={information?.Footer}/>
                        </div>
                        }>

                    </Route>
                </Routes>
              
            </ThemeProvider>
		</Router>
		</div>
	);


}







export default App;
