import { useLayoutEffect, useRef, useState } from 'react'

//gsap
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

//mui
import Button from '@mui/material/Button'
import MobileStepper from '@mui/material/MobileStepper'
import useMediaQuery from '@mui/material/useMediaQuery';

import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io'

//mui icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';




gsap.registerPlugin(Flip) 
interface Iprops {information:{[key:string]:string;}}
const Websites2 = (props:Iprops) => {
    const { information } = props

    const matches = useMediaQuery('(max-width:690px)');
    const topPosition = matches ? "20vh":"30vh"
    const leftPosition = matches ? "0.5rem":"4rem"

    const website_ref = useRef(null)
    const image_ref = useRef<HTMLDivElement[] | null []>([]);

    const [elements, setElements] = useState<HTMLElement []>([])
    const [currentImage, setCurrentImage] = useState(0)

   
    const imagesData = [
        {
            title:"Sjöporten \n Restaurant",
            info:"Hello World!",
            image:"/img/website1.webp",
            link:"https://sjoporten.se",
            color:"#ffffff"
        },
        {
            title:"Fitness \n Filosofi \n Floskler",
            info:"Hello World!",
            image:"/img/website2.webp",
            link:"https://fitnessfilosofifloskler.se",
            color:"#efc050"
        },
        {
            title:"Klippans Konstcafè",
            info:"Hello World!",
            image:"/img/website3.webp",
            link:"https://klippanskonstcafe.se",
            color:"#92d1b0"
            
        },
        {
            title:"Afekcii Grill",
            info:"Hello World!",
            image:"/img/website4.webp",
            link:"https://showcase1.afekcii.se",
            color:"#dd2a5a"
        },
        {
            title:"Restaurant Afekcii",
            info:"Hello World!",
            image:"/img/website5.webp",
            link:"https://showcase2.afekcii.se",
            color: "#ffffff"//"#d7a33c"
        }
    ]

    const ShiftImage = (sign:string) => {
       
        const ctx = gsap.context(() => {           

            gsap.to(image_ref.current, {
              x: `${sign}=14rem`,
              delay: sign === "+" ? 0.5 : 0.5,
              duration:0.5
            })


        }, website_ref); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }   
    const NextImage = () => {
        const imageEl = document.querySelector(`#dynamicImage${currentImage} img`) as HTMLImageElement;
        const textEl = document.querySelector(`#dynamicImage${currentImage} h1`) as HTMLElement;
        const buttonEl = document.querySelector(`#dynamicImage${currentImage} a`) as HTMLElement;
        const ctx = gsap.context(async() => {    
           
           
            const fixed = document.getElementsByClassName("fullscreen-size")[0];
            const imageContainer = document.getElementsByClassName("image-container")[0];
            const numberOfImages = imagesData.length
          
          
            
            const state = Flip.getState(imageEl);                  
            
            if( currentImage < numberOfImages){      
                
                // removes text element from image-container
                gsap.to(buttonEl, {opacity:0, duration:0.2 });
                await gsap.to(textEl, { top:0, opacity:0,duration:0.2 });

                
                // adds text and img element to fullscreen
                fixed.appendChild(imageEl); 
                fixed.appendChild(textEl);
                fixed.appendChild(buttonEl);
                
                // fullscreen element = visible + animation Flip
                gsap.set(fixed, { autoAlpha: 1 });                
                Flip.from(state, {
                    duration: 0.5,
                    transformOrigin: "center center",
                    ease: "easeInOut"
                });
                gsap.to(imageEl, { borderRadius:0, duration:0.5 });

                // shift small images to left
                ShiftImage("-")  

                // text in fullscreen will slide in with fade in effect
                gsap.set(textEl, { fontSize:"3rem", left: leftPosition });
                gsap.set(buttonEl, { fontSize:"1rem", left: leftPosition });
                gsap.fromTo(textEl, { autoAlpha: 0 },{ autoAlpha: 1,duration:0.5,delay:0.7 });
                gsap.to(textEl, { top: topPosition,/* zIndex:1, */delay:0.7 });
                gsap.fromTo(buttonEl, {top:0,bottom:"100%"},{  opacity:1,top: topPosition,delay:0.7 });

                imageEl.classList.toggle("active");
                setCurrentImage(currentImage+1)
                setElements(prev => [...prev,imageEl])
            }
          
            
            
       

            
            
        }, website_ref); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }
    
    const PrevImage = () => {
        //const imageEl = document.querySelector(`#dynamicImage${currentImage} img`) as HTMLImageElement;
        const textEl = document.querySelector(`.fullscreen-size  #dynamicText${currentImage-1}`) as HTMLElement;
        const buttonEl = document.querySelector(`.fullscreen-size #dynamicButton${currentImage - 1}`) as HTMLElement;
        const ctx = gsap.context(async() => {    
           
          
            const fixed = document.getElementsByClassName("fullscreen-size")[0];
            const imageContainer = document.querySelector(`.image-container #dynamicImage${currentImage-1}`)
            const numberOfImages = imagesData.length
          
            let imageEl = elements[elements.length - 1]
            
            const state = Flip.getState(imageEl);
            

            
          
            
            
            if (imageContainer && imageEl?.classList?.contains("active") && currentImage > 0 ) { 
                                
                // Fades out the text in the fullscreen
                
                gsap.to(buttonEl, { top: 0,opacity:0, duration: 0.5 });
                await gsap.to(textEl, { top: 0,opacity:0, duration: 0.5 });
                
                
                // Removes the text and image from the fullscreen
                imageContainer.appendChild(imageEl)
                imageContainer.appendChild(textEl)
                imageContainer.appendChild(buttonEl)
                
              
                
                //imageContainer.insertBefore(<div>{imageEl}</div>, imageContainer.children[currentImage-1]);                                
                //gsap.set(fixed, { autoAlpha: 0 });

                // Flip Animation for the image
                Flip.from(state, {
                    duration: 0.5,
                    delay: 0,
                    //scale: true,
                    ease: "linear"
                })
                gsap.to(imageEl, { borderRadius:"1rem", duration:0.5 });
                // Shifts img to the left
                ShiftImage("+")  

                // Fades in the text to the small image, original position
                gsap.set(textEl, { fontSize:"1.2rem", left:"0.5rem"});
                gsap.to(textEl, { opacity:1,delay:0.5 , duration:0.5});

                gsap.set(buttonEl, { bottom:0, top:"revert-layer", left:"0.5rem"});
                gsap.to(buttonEl, { opacity:1,delay:0.5 , duration:0.5});

                imageEl.classList.toggle("active");
                setCurrentImage(currentImage-1)
                setElements([
                    ...elements.slice(0,elements.length - 1),
                    ...elements.slice(elements.length)
                ]) //removes last element in array
            } 
       

            
            
        }, website_ref); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }
 



    return (
        <div className='flex__center-c fullcontainer websites2'  id='websites' style={{  minHeight:"100vh",width:"100vw",position:"relative",justifyContent:"flex-end", overflow:"hidden" }} ref={website_ref}>

            <div className="fullscreen-size"  style={{  position:"absolute", height:"100%" }}>
               
                <h1 style={{fontSize:"3rem",zIndex:1,position:"absolute",left: leftPosition, top:topPosition, color:"white"}}>{information?.Text_Title} </h1>
                <img src={`/img/website0.webp`} alt="" style={{objectFit:"cover"}}/>
                
            </div>

            {/* Carusell */}
            <div className='flex__center-r image-container' style={{position:"absolute",bottom:"5rem", left:"50%"}}>

            
                {imagesData.map((item,i) => {
                    return (
                        <div key={`dynamicImageKey${i}${item.title}`} className='item ' id={`dynamicImage${i}`} style={{zIndex:i+1,width:"12rem", height:"20rem",position:"relative",marginRight:"2rem"}} ref={el => (image_ref.current[i] = el)} >
                            <h1 id={`dynamicText${i}`} style={{textAlign:"left",fontSize:"1.1rem",zIndex:1,position:"absolute",left:"0.5rem", color:item?.color}}>{item?.title}</h1>
                            <Button  id={`dynamicButton${i}`} href={item?.link} target="_blank" endIcon={<PlayArrowIcon />} 
                                sx={{textTransform:"none",fontSize:"1rem",zIndex:1,position:"absolute",left: "0.5rem", bottom:0 , color:item?.color,'&:hover':{backgroundColor:"#ffffff00"}}}>  
                                {information?.Button_VisitSite} 
                            </Button>
                            <img className="img-frame" src={item?.image} alt="" style={{height:"100%",width:"100%" ,position:"absolute" ,top:0,left:0,objectFit:"cover",filter: "brightness(0.5)"}}/>
                        </div>
                    )
                })}

                

            </div>

            {/* Button to move imgs of caruesll left and right */}
            <div style={{zIndex:100,marginBottom:"1rem"}} >
             

            {/*     <button onClick={NextImage}>
                    NEXT
                </button>

                <button onClick={PrevImage}>
                    PREV
                </button> */}


                <MobileStepper
                    variant="progress"
                    steps={imagesData.length+1}
                    position="static"
                    activeStep={currentImage}
                    sx={{ 
                        width: 600, flexGrow: 1,bgcolor:"#ffffff00",maxWidth:"100vw",
                        '& .MuiMobileStepper-progress':{
                            height:"0.7rem",
                            borderRadius:"1rem",
                            backgroundColor:"#ffffff1b"
                        }

                    }}
                 
                    
                    nextButton={
                        <Button  onClick={NextImage} disabled={currentImage === imagesData.length} color="info" >
                        
                            <IoIosArrowDropright  style={{width:"2rem",height:"2rem"}}/>
                        
                        </Button>
                    }
                    backButton={
                        <Button  onClick={PrevImage} disabled={currentImage === 0} color="info">

                            <IoIosArrowDropleft  style={{width:"2rem",height:"2rem"}}/>
                                                                            
                        </Button>
                    }
                    />

            </div>
            
        </div>
    )
}

export default Websites2