import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Link } from 'react-scroll'

// react icons
import { MdComputer } from 'react-icons/md'


// SELF IMPORTS
import './Header.css'
import { ReactComponent as YourSvg } from '../../images/Component 1.svg';
interface infoinit {
  information:{
    [key:string]: string    
  }
}
const Header = ({information}:infoinit) => {

	const header_info:any = useRef()
	const header_ref = useRef(null)
	const tl1:any = useRef(null);

    useEffect(() => {
      window.scrollTo(0,0)
    
      return () => {
        
      }
    }, [])
    

	/* Page load => fade in main text*/
	useLayoutEffect(() => {
		const ctx = gsap.context(()=>{ 
			tl1.current = gsap.timeline()

		


			.fromTo('#react-icon-logo',{
				filter: "blur(10px) brightness(0.8)",
				opacity:0
			},{
				filter: "blur(0px) brightness(0.8)", 
				opacity:1,
				duration: 1,
				ease: 'power3.inOut'
			},0)

			
			.to(".loading-circle", {
				"--p": '100%',
		
				duration: 1.75,
				ease: 'Power4.ease'				
			},1)
			.to('#react-icon-logo',{
				width: "100px",
				height: "100px",
				duration: 1,

			},3)
			.fromTo('.header__info-first h1',{
				filter: "blur(10px)",
				opacity: 0,

			},{
				filter: "blur(0px)",
				opacity: 1,
				ease: 'Power4.ease'	,
				duration: 1.5,
			},4)
			.fromTo('.header__info-first .header__title-sqrbtn',{
				filter: "blur(10px)",
				opacity: 0,

			},{
				filter: "blur(0px)",
				opacity: 1,
				ease: 'Power4.ease'	,
				duration: 1.5,
			},4)

	


			.to('.loading-circle',{			
				opacity:0
			},4)
		/* 	.fromTo('.header__info-second',{
				filter: "blur(10px) brightness(0.8)",
				opacity:0
			},{
				filter: "blur(0px) brightness(0.8)", 
				opacity:1,
			
				ease: 'power3.inOut'
			},4) */
			
			.to('.header__info',{			
				height: "auto",
				width: "auto",
				zIndex:0
			},4)
			

			
		},header_ref)
	
		return () => {
		ctx.revert()
		};
	}, [])
		
	return (    
		<div className='container__header flex__center-c'ref={header_ref} id='header'>

			<div className='header__info flex__center-c' ref={header_info}>

				

				<div className='header__info-first flex__center-c'>
					
					<h1 className='h1__font-large first-h1'>{information?.First_Element}</h1>
					<YourSvg id='react-icon-logo'/>								
					<h1 className='h1__font-medium second-h1'>{information?.Second_Element}</h1>
					
					<Link className='navbar__navigation-sqrbtn header__title-sqrbtn flex__center-c' activeClass="active" to="contact" spy={true} smooth={true} offset={50} duration={500}>
						<button className='p__font-medium square-btn' >{information?.Contact_Button}</button>
					</Link>
			

					<div className='loading-circle flex__center-c'>
						<div className='circle ' />													
					</div>
					
					
				</div>

				{/* <div className='header__info-second flex__center-c'>
					
					<h1 className='h1__font-medium'>{information?.First_Element}</h1>
					<h1 className='h1__font-small'>{information?.Second_Element}</h1>
					<div className='flex'>
						<h1 className='h1__font-medium' id='title-name'>{information?.Third_Element}</h1>          
						<h1 className='h1__font-medium'>{information?.Fourth_Element} </h1>
					</div>
				</div> */}
				
			{/* <div className='header__buttons flex__center-c'>
				<button>{information?.More_Button}</button>
				<button>{information?.Contact_Button}</button>
				<button className='glowing-btn '><span className='glowing-txt'>C<span className='faulty-letter'>O</span>NTACT</span></button>
				<button className='glowing-btn'><span className='glowing-txt'>M<span className='faulty-letter'>O</span>RE</span></button>
			</div> */}
			</div>

		</div>
	)
}

export default Header