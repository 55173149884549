import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


const serverAddress = process.env.NODE_ENV === "production" ? "https://api.afekcii.se" : "http://localhost:5000"


//* Booking
export const PostContact = createAsyncThunk(
    "post/PostContact",
    async(info: any,{ getState } ) => {
        const res = await axios.post(`${serverAddress}/postcontact`,info)        
        return res.data
})




const postSlice = createSlice({
    name:"contact",
    initialState: {},
    reducers:{},
    extraReducers: builder => {                
                
    }
})

const { reducer } = postSlice

export default reducer

 