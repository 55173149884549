import React from 'react'

import './Iphonex.css'
const Iphonex = () => {
  return (
    <div className="iphonex">
        <div className="front">
            <div className="screen">
                <div className="screen__view flex-c">
                <img className='mobilescroll__bgimg-img' src={`/img/mobilescroll1.webp`} alt={`mobilescroll1`} id='mobilescroll-img1'/>
                <img className='mobilescroll__bgimg-img' src={`/img/mobilescroll2.webp`} alt={`mobilescroll1`} id='mobilescroll-img2'/>
                <img className='mobilescroll__bgimg-img' src={`/img/mobilescroll3.webp`} alt={`mobilescroll1`} id='mobilescroll-img3'/>
                <img className='mobilescroll__bgimg-img' src={`/img/mobilescroll4.webp`} alt={`mobilescroll1`} id='mobilescroll-img4'/>                
                </div>
                <div className="screen__front">
                    <div className="screen__front-speaker"></div>
                    <div className="screen__front-camera"></div>
                </div>
            </div>
            <div className="front__line"></div>
            <div className="front__line front__line-second"></div>
        </div>
        <div className="phoneButtons phoneButtons-right"></div>
        <div className="phoneButtons phoneButtons-left"></div>
        <div className="phoneButtons phoneButtons-left2"></div>
        <div className="phoneButtons phoneButtons-left3"></div>
        <div className="back"></div>
    </div>
  )
}

export default Iphonex