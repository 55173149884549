import { useEffect,useState, useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
// SELF IMPORTS
import './Tools.css'

interface infoinit {
  information:{
    First_Element: string
    Second_Element: string
    Img: string
    Second_Element_Color: string

  },
  componentNumber: number
}
gsap.registerPlugin(ScrollTrigger);
interface Iprops {
    information: {[key:string]:string},
    componentNumber: number
}
const Tools = (props:Iprops) => {
    const {information, componentNumber} = props
  const tools:any = useRef()
  const tool_img:any = useRef()
  



  //* GSAP */

  /* Pin background img  */
  useLayoutEffect(() => {
    const ctx = gsap.context(()=>{

		gsap.set('.tools__bgimg-img',{
			width: "80vw",
			height: "80vh",
		
		})
		gsap.set('.tools__info',{
			zIndex:"2",
			color: "white",
			
		})

			
		gsap.to('.tools__bgimg',{                      
			scrollTrigger: {         
			trigger: tools.current,       
			start: "25% center",
			/*  markers: true, */
			pin: tool_img.current,
			toggleActions: "play none none reverse",          
			}
		})
		gsap.to('.tools__bgimg-img',{                      
			height: "100vh",
			width: "100vw",
			filter: "brightness(0.3)",
			scrollTrigger: {         
			trigger: tools.current,       
			start: "25% center",         
			toggleActions: "play none none reverse",          
			}
		})



      
      




    },tools)
  
    return () => {
      	ctx.revert()
    };
  }, [])
 
    
  return (    
    <div className={`tools${componentNumber} tools flex-c`} ref={tools} id={`tools${componentNumber}`}>

      <div className='tools__bgimg flex__center-c' ref={tool_img}>
        <img className='tools__bgimg-img' src={information?.Img} alt={`tools1`} />
      </div>

      <div className='tools__info flex-c'>
        <h3 className='h2__font-large'>{information?.First_Element}<span style={{background:information?.Second_Element_Color}}>{information?.Second_Element}</span></h3>       
      </div>
  

    </div>
  )
}

export default Tools